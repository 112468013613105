import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import UIHelper from '../../utility/ui-helper';
import Fade from 'react-reveal/Fade';
import { Element } from 'react-scroll';

import CloudDownload from '@material-ui/icons/CloudDownload';
import Link from '@material-ui/icons/Link';

const styles = theme => ({ 
  root: {
  },
  logo: {
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.7'
    },
    width: 120,
    height: 120,
    [theme.breakpoints.down('md')]: {
      width: 75,
      height: 75
    },
    [theme.breakpoints.down('sm')]: {
      width: 50,
      height: 50
    }
  },
  certificationName: {
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'rgba(52,73,94,0)',
      backgroundColor: 'rgba(52,73,94,0.1)'
    }
  }
});

const certifications = [
  {
    name: 'AWS Certified Solutions Architect - Professional',
    date: 'December 18, 2018',
    certDownloadLink: './resources/certifications/AWSSolutionsArchitectProfessional.pdf',
    badgeLogo: './resources/badges/aws-solutions-architect-professional-150x150.png',
    badgeLink: 'https://www.certmetrics.com/amazon/public/badge.aspx?i=4&t=c&d=2018-12-18&ci=AWS00615697',
    descriptionLink: '',
    certNumber: 'DCZEYEJ2CBFQQ1CG'
  },
  {
    name: 'AWS Certified DevOps Engineer - Professional',
    date: 'December 06, 2018',
    certDownloadLink: './resources/certifications/AWSDevOpsEngineerProfessional.pdf',
    badgeLogo: './resources/badges/aws-devops-engineer-professional-150x150.png',
    badgeLink: 'https://www.certmetrics.com/amazon/public/badge.aspx?i=5&t=c&d=2018-12-06&ci=AWS00615697',
    descriptionLink: '',
    certNumber: 'KM64LTMKKEB41BGF'
  },
  {
    name: 'AWS Certified SysOps Administrator - Associate',
    date: 'October 15, 2018',
    certDownloadLink: './resources/certifications/AWSSysOpsAdministratorAssociate.pdf',
    badgeLogo: './resources/badges/aws-sysops-administrator-associate-150x150.png',
    badgeLink: 'https://www.certmetrics.com/amazon/public/badge.aspx?i=3&t=c&d=2018-10-15&ci=AWS00615697',
    descriptionLink: '',
    certNumber: 'FQ5FWG0C1F4EQGSR'
  },
  {
    name: 'AWS Certified Developer - Associate',
    date: 'October 05, 2018',
    certDownloadLink: './resources/certifications/AWSDeveloperAssociate.pdf',
    badgeLogo: './resources/badges/aws-developer-associate-150x150.png',
    badgeLink: 'https://www.certmetrics.com/amazon/public/badge.aspx?i=2&t=c&d=2018-10-05&ci=AWS00615697',
    descriptionLink: '',
    certNumber: 'C29FV9PC1FVQ1C5Q'
  },
  {
    name: 'AWS Certified Solutions Architect - Associate',
    date: 'September 24, 2018',
    certDownloadLink: './resources/certifications/AWSSolutionsArchitectAssociate.pdf',
    badgeLogo: './resources/badges/aws-solutions-architect-associate-150x150.png',
    badgeLink: 'https://www.certmetrics.com/amazon/public/badge.aspx?i=1&t=c&d=2018-09-24&ci=AWS00615697',
    descriptionLink: '',
    certNumber: 'W3FSQEPCK2FEQQ5P'
  }
];

class Certifications extends Component {

  state = {}

  constructor (props) {
    super(props);
  }

  onDownloadCertification = (downloadLink) => {
    window.open(downloadLink);
  }

  onViewBadge = (badgeLink) => {
    window.open(badgeLink);
  }

  render = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Element name='certifications'>
          <Fade clear delay={200}>
            <Divider variant="middle" />
            <br />
            <br />
            
            <Typography variant="h4">Certifications</Typography>
            <List component="nav">
              {
                certifications.map((certification, index) => {
                  return (
                    <ListItem>
                      <ListItemIcon  onClick={ () => { this.onViewBadge(certification.badgeLink) } }>
                        <img src={certification.badgeLogo} className={ classes.logo } />
                      </ListItemIcon>
                      <ListItemText 
                        primary={
                          <Fragment>
                            <Typography 
                              className={classes.certificationName}
                              onClick={ () => { this.onViewBadge(certification.badgeLink) } }>
                            { certification.name }
                            </Typography>
                          </Fragment>
                        } 
                        secondary={ 
                          <Fragment>
                            <Typography variant="caption" gutterBottom={true}>{certification.date}</Typography>
                            <Typography variant="caption">Certification Number: {certification.certNumber}</Typography>
                            <Typography variant="caption">Validation URL: https://aws.amazon.com/verification</Typography>  
                          </Fragment> } 
                      />
              
                      <ListItemSecondaryAction>
                        <IconButton aria-label="download" onClick={ () => { this.onViewBadge(certification.badgeLink) } }>
                          <Link />
                        </IconButton>
                        <IconButton aria-label="download" onClick={ () => { this.onDownloadCertification(certification.certDownloadLink) } }>
                          <CloudDownload />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>      
                  )
                })
              }
            </List>
          </Fade>
        </Element>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({});
const mapDispatchToProps = (dispatch, props) => ({});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Certifications));