import * as serviceWorker from './serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-vertical-timeline-component/style.min.css';
import AppRouter from './routers/AppRouter';

// import registerServiceWorker from './registerServiceWorker';

import { Provider } from 'react-redux';
import store from './store/store';
import UIHelper from './utility/ui-helper';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// src: https://material-ui.com/customization/themes/
// const FONT_COLOR = '#404d5b';
const FONT_COLOR = UIHelper.FONT_COLOR;

const theme = createMuiTheme({
  palette: {
    primary: { 
      main: '#2650a2'
    },
    secondary: { 
      main: '#00b04f',
      contrastText: '#fff'
    },
    // ,
    // secondary: { main: '#11cb5f' }, // This is just green.A700 as hex.
  },
  typography: {
    fontFamily: `Calibre, "San Francisco", "SF Pro Text", -apple-system, system-ui, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", Arial, sans-serif`
  },
  overrides: {
    MuiTypography: {
      h1: {
        color: FONT_COLOR
      },
      h2: {
        color: FONT_COLOR
      },
      h4: {
        color: FONT_COLOR
      },
      h5: {
        color: FONT_COLOR
      },
      h6: {
        color: FONT_COLOR
      },
      p: {
        color: FONT_COLOR
      },
      span: {
        color: FONT_COLOR
      },
      subtitle1: {
        color: FONT_COLOR
      },
      subtitle2: {
        color: FONT_COLOR
      },
      subheading: {
        color: FONT_COLOR
      }
    },
    MuiButton: {
      flat: {
        color: FONT_COLOR
      },
      outlined: {
        color: FONT_COLOR
      }
    },
    MuiChip: {
      outlined: {
        color: FONT_COLOR
      }
    }
  }
});

const jsx = (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <AppRouter/>
    </MuiThemeProvider>
  </Provider>
);

ReactDOM.render(jsx, document.getElementById('root'));
// registerServiceWorker();
