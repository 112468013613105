import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
// import productsReducer from '../reducers/products';
// import cartReducer from '../reducers/cart';
// import priceComparisonReducer from '../reducers/pricecomparison';
// import location from '../reducers/location';
// import searchfilters from '../reducers/searchfilters';
// import session from '../reducers/session';
// import user from '../reducers/user';
// import suggestions from '../reducers/suggestions';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      // products: productsReducer,
      // cart: cartReducer,
      // pricecomparison: priceComparisonReducer,
      // location: location,
      // searchfilters: searchfilters,
      // session: session,
      // user: user,
      // suggestions: suggestions
    }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};
