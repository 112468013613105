import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import LaptopMac from '@material-ui/icons/LaptopMac';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import Fade from 'react-reveal/Fade';
import { Element } from 'react-scroll';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import UIHelper from '../../utility/ui-helper';

const styles = theme => ({ 
  root: {

  },
  portfolio: {
    marginTop: theme.spacing.unit * 2
    // ,
    // background: '#ccc'
    // marginBottom: theme.spacing.unit * 100
  },
  companyLogo: {

  },
  'externalLink': {
    ...UIHelper.externalLink
  },
  chip: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  showCasePhoto: {
    width: '650px',
    marginTop: theme.spacing.unit * 2,
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.7'
    },
    [theme.breakpoints.down('lg')]: {
      width: '450px'
    },
    [theme.breakpoints.down('md')]: {
      width: '450px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '250px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '200px'
    }
  }

});

class Portfolio extends Component {

  state = {
    isLightboxOpen: false,
    // imgSrc: '',
    photoIndex: 0,
    companySlidesBeingViewed: ''
  }

  showCaseImages = {
    'salonmonster': './resources/projects/showcase/showcase-salonmonster.jpg',
    'bettercart': './resources/projects/showcase/showcase-bettercart.jpg',
    'campertunity': './resources/projects/showcase/showcase-campertunity.jpg',
    'projecttrue': './resources/projects/showcase/showcase-projecttrue.jpg',
    'aws': './resources/projects/showcase/showcase-aws.jpg'
  }

  slides = {
    'salonmonster': [
      this.showCaseImages['salonmonster'],
      './resources/projects/showcase/slides/salonmonster/imac.png',
      './resources/projects/showcase/slides/salonmonster/ipad.png',
      './resources/projects/showcase/slides/salonmonster/iphone.png'
    ],
    'bettercart': [
      this.showCaseImages['bettercart'],
      './resources/projects/showcase/slides/bettercart/imac.png',
      './resources/projects/showcase/slides/bettercart/ipad.png',
      './resources/projects/showcase/slides/bettercart/iphone.png'
    ],
    'campertunity': [
      this.showCaseImages['campertunity'],
      './resources/projects/showcase/slides/campertunity/imac.png',
      './resources/projects/showcase/slides/campertunity/ipad.png',
      './resources/projects/showcase/slides/campertunity/iphone.png'
    ],
    'projecttrue': [
      this.showCaseImages['projecttrue'],
      './resources/projects/showcase/slides/projecttrue/imac.png',
      './resources/projects/showcase/slides/projecttrue/macbook.png',
      './resources/projects/showcase/slides/projecttrue/ipad.png'
    ],
    'aws': [
      this.showCaseImages['aws'],
      './resources/projects/showcase/slides/aws/contact-lens-category-hit.png',
      './resources/projects/showcase/slides/aws/contact-lens-navigate-transcripts.png',
      './resources/projects/showcase/slides/aws/ipad.png'
    ],
  }

  constructor (props) {
    super(props);
  }

  openLightBox = (companyName) => {
    this.setState({
      isLightboxOpen: true,
      photoIndex: 0,
      companySlidesBeingViewed: companyName
      // imgSrc: this.showCaseImages[companyName]
    });
  }

  render = () => {
    const { classes } = this.props;
    const { photoIndex, isLightboxOpen, companySlidesBeingViewed } = this.state;
    return (
      <div className={classes.portfolio}>
        <Element name='portfolio'>
          <Fade clear delay={200}>
            <Divider variant="middle" />
            <br />
            <br />
            <Typography variant="h4">Featured Projects</Typography>
          </Fade>
            <VerticalTimeline layout="1-column">

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="2019 - present"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={
                  <Avatar alt="aws" src="./resources/projects/companies/aws.png" className={classes.companyLogo} />
                }
              >
                <Typography variant="title">Front-End Engineer II</Typography>
                <Typography variant="subtitle2"><a href="https://aws.amazon.com/connect/contact-lens/" target="_blank" className={classes.externalLink}>@AmazonWebServices </a></Typography>
                <img onClick={() => this.openLightBox('aws')} alt="aws" src={this.showCaseImages['aws']} className={classes.showCasePhoto} />
                <p>
                  <ul>
                    <li>
                      <Typography variant="caption">Contact Lens for Amazon Connect is a set of machine learning (ML) capabilities integrated into Amazon Connect. With Contact Lens for Amazon Connect, contact center supervisors can better understand the sentiment, trends, and compliance of customer conversations to effectively train agents, replicate successful interactions, and identify crucial company and product feedback.</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Built a brand new audio player from scratch using WebGL. Amplitude (loudness score) visualization, speaker sentiment, interruptions and non-talk time. Cross-browser & fallback support to HTML5 Canvas.</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Voice and chat transcript (with sentiment analysis & auto-scrolling feature)</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Transcript issue detection and data redaction</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Automated contact categorization</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Advanced Contact Search (transcript search, sentiment search, non-talk time search)</Typography>
                    </li>
                  </ul>

                  <Chip label="ReactJS" className={classes.chip} variant="outlined" />
                  <Chip label="Typescript" className={classes.chip} variant="outlined" />
                  <Chip label="PixiJS" className={classes.chip} variant="outlined" />
                  <Chip label="WebGL" className={classes.chip} variant="outlined" />
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="2016 - 2019"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={
                  <Avatar alt="salonmonster" src="./resources/projects/companies/salonmonster.png" className={classes.companyLogo} />
                }
              >
                <Typography variant="title">Software Engineer, Lead</Typography>
                <Typography variant="subtitle2"><a href="https://salonmonster.com" target="_blank" className={classes.externalLink}>@salonMonster </a></Typography>
                <img onClick={() => this.openLightBox('salonmonster')} alt="salonmonster" src={this.showCaseImages['salonmonster']} className={classes.showCasePhoto} />
                <p>
                  <ul>
                    <li>
                      <Typography variant="caption">Led the initiative to convert the legacy app to a responsive single-page app using Ionic 3 / Angular 5, which reduced load times by ~60%</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Rewrote the legacy PHP API using NodeJS/ExpressJS & Typescript</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Rewrote the client booking application using Angular 5+</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Developed the Point-of-Sale feature which integrates with Square POS. This enables users to accept in-person payments using their smartphones/tablets</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Built the Reports feature to help users visualize & analyze their sales, client retention, tips and taxes data</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Built the Register Closure feature</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Mobile native features - push notifications, barcode scanner, camera, contacts import</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Email & SMS reminder script using Twilio to remind clients & stylists of their upcoming appointments</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Third party SaaS integration - Zendesk, Intercom, SendGrid, MailChimp, OneSignal</Typography>
                    </li>
                  </ul>

                  <Chip label="Angular 5" className={classes.chip} variant="outlined" />
                  <Chip label="Ionic 3" className={classes.chip} variant="outlined" />
                  <Chip label="iOS" className={classes.chip} variant="outlined" />
                  <Chip label="Android" className={classes.chip} variant="outlined" />
                  <Chip label="Cordova" className={classes.chip} variant="outlined" />
                  <Chip label="NodeJS" className={classes.chip} variant="outlined" />
                  <Chip label="MySQL" className={classes.chip} variant="outlined" />
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="2014 - 2016"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={
                  <Avatar alt="salonmonster" src="./resources/projects/companies/salonmonster.png" className={classes.companyLogo} />
                }
              >
                <Typography variant="title">Junior Engineer</Typography>
                <Typography variant="subtitle2"><a href="https://salonmonster.com" target="_blank" className={classes.externalLink}>@salonMonster</a></Typography>
                <p>
                  <ul>
                    <li>
                      <Typography variant="caption">Maintained the legacy PHP application</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Built the Point-of-Sale feature using JS/Jquery</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Developed the products, tips and discounts manager using AngularJS</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Migrated the mobile application from Sencha Touch 1 to Sencha Touch 2.0</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Designed & developed the RESTful PHP API</Typography>
                    </li>
                  </ul>

                  <Chip label="PHP" className={classes.chip} variant="outlined" />
                  <Chip label="AngularJS" className={classes.chip} variant="outlined" />
                  <Chip label="Sencha Touch 2 / EXTJS" className={classes.chip} variant="outlined" />
                  <Chip label="Cordova" className={classes.chip} variant="outlined" />
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="Nov 2018 - Jan 2019"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={
                  <Avatar alt="salonmonster" src="./resources/projects/companies/bettercart.png" className={classes.companyLogo} />
                }
              >
                <Typography variant="title">Full Stack Developer (Freelance)</Typography>
                <Typography variant="subtitle2"><a href="https://bettercart.ca" target="_blank" className={classes.externalLink}>@BetterCart</a></Typography>
                <img onClick={() => this.openLightBox('bettercart')} alt="bettercart" src={this.showCaseImages['bettercart']} className={classes.showCasePhoto} />
                <p>
                  <ul>
                    <li>
                      <Typography variant="caption">Built automated web crawlers that extract product inventory from 20 grocery chains in Canada</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Designed an algorithm to match and compare 100k products from different grocery stores</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Designed & developed the web app using ReactJS / Redux</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Built the RESTful API using NodeJS / ExpressJS and ElasticSearch</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Helped reduce cost by taking advantage of AWS AutoScaling, AWS SQS, AWS S3 for job processing</Typography>
                    </li>
                  </ul>

                  <h4>🙌🏾 Featured in:</h4>
                  <ul>
                    <li>
                      <Typography variant="caption"><a target="_blank" href='https://globalnews.ca/news/7036688/coronavirus-groceries-bettercart-app-whats-in-stock/'>Global News - Coronavirus: App aims to make it easier to shop for groceries in Saskatchewan</a></Typography>
                    </li>
                    <li>
                      <Typography variant="caption"><a target="_blank" href='https://www.ckom.com/2020/05/22/saskatoon-developed-website-lets-you-check-grocery-store-stock/'>CKom - Saskatoon-developed website lets you check grocery store stock</a></Typography>
                    </li>
                    <li>
                      <Typography variant="caption"><a target="_blank" href='https://www.agwest.sk.ca/blog/posts/bettercart-helps-shoppers-save-money-and-stay-safe.html'>Ag-West Bio - BetterCart helps shoppers save money and stay safe</a></Typography>
                    </li>
                  </ul>

                  <Chip label="ReactJS" className={classes.chip} variant="outlined" />
                  <Chip label="Redux" className={classes.chip} variant="outlined" />
                  <Chip label="NodeJS / ExpressJS" className={classes.chip} variant="outlined" />
                  <Chip label="ElasticSearch" className={classes.chip} variant="outlined" />
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="March 2017 - October 2017"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={
                  <Avatar alt="campertunity" src="./resources/projects/companies/Campertunity.png" className={classes.companyLogo} />
                }
              >
                <Typography variant="title">Full Stack Developer (Freelance)</Typography>
                <Typography variant="subtitle2"><a href="https://campertunity.com" target="_blank" className={classes.externalLink}>@Campertunity</a></Typography>
                <img onClick={() => this.openLightBox('campertunity')} alt="campertunity" src={this.showCaseImages['campertunity']} className={classes.showCasePhoto} />
                <p>
                  <ul>
                    <li>
                      <Typography variant="caption">Built campground listing, availability calendar and booking management features</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Designed & developed the web app using Angular 5+ / Ionic 3+</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Built the API using NodeJS / ExpressJS / MySQL</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Online payment processing using Stripe Payments API</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Custom UI component for searching campgrounds and places (using Google Place API)</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Automated script to remind users of their upcoming trip</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Developed an internal application for administrators to help manage bookings, users, and keep track of payouts</Typography>
                    </li>
                  </ul>

                  <h4>🙌🏾 Featured in:</h4>
                  <ul>
                    <li>
                      <Typography variant="caption"><a target="_blank" href='https://www.cbc.ca/news/canada/british-columbia/business-sharing-economy-camping-1.5057159'>CBC News - B.C. business helps people rent their properties as campsites</a></Typography>
                    </li>

                    <li>
                      <Typography variant="caption"><a target="_blank" href='https://globalnews.ca/news/4293241/airbnb-of-camping-takes-aim-at-crowded-b-c-campgrounds/'>Global News - ‘Airbnb of camping’ takes aim at crowded B.C. campgrounds</a></Typography>
                    </li>

                    <li>
                      <Typography variant="caption"><a target="_blank" href='https://www.vancouverisawesome.com/outdoors/website-book-list-campsites-private-property-across-bc-2434251'>VancouverIsAwesome - This website lets you book and list campsites on private property across B.C.</a></Typography>
                    </li>

                    <li>
                      <Typography variant="caption"><a target="_blank" href='https://www.kelownanow.com/news/news/Lifestyle/Peace_and_tranquility_assured_Website_created_by_BC_duo_pushes_camping_into_the_shared_economy/'>KelownaNow - 'Peace and tranquility assured': Website created by BC duo pushes camping into the shared economy</a></Typography>
                    </li>

                    <li>
                      <Typography variant="caption"><a target="_blank" href='https://www.saanichnews.com/trending-now/app-allows-campers-to-book-sites-in-peoples-backyards/'>Saanich News - App allows campers to book sites in people’s backyards</a></Typography>
                    </li>
                    
                  </ul>

                  <Chip label="Angular 2+" className={classes.chip} variant="outlined" />
                  <Chip label="Ionic 3+" className={classes.chip} variant="outlined" />
                  <Chip label="NodeJS / ExpressJS" className={classes.chip} variant="outlined" />
                  <Chip label="MySQL" className={classes.chip} variant="outlined" />
                </p>

              </VerticalTimelineElement>


              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="December 2017 - February 2018"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={
                  <Avatar alt="projecttrue" src="./resources/projects/companies/projecttrue.jpg" className={classes.companyLogo} />
                }
              >
                <Typography variant="title">Full Stack Developer (Freelance)</Typography>
                <Typography variant="subtitle2"><a href="https://projecttrue.com" target="_blank" className={classes.externalLink}>@ProjectTrue</a></Typography>
                <img onClick={() => this.openLightBox('projecttrue')} alt="projecttrue" src={this.showCaseImages['projecttrue']} className={classes.showCasePhoto} />
                <Typography variant="caption" >Currently on private beta</Typography>
                <p>
                  <ul>
                    <li>
                      <Typography variant="caption">Built app that helps youth find eating disorder resources in their community using search filters & map</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Designed & developed the web app using Angular 5+ / Ionic 3+</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Built the API using NodeJS / ExpressJS / MySQL</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Ability for professionals to list their resource</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Ability for users to share reviews & comments.</Typography>
                    </li>
                    <li>
                      <Typography variant="caption">Admin application for internal-use to help manage resources & users</Typography>
                    </li>
                  </ul>

                  <Chip label="Angular 2+" className={classes.chip} variant="outlined" />
                  <Chip label="Ionic 3+" className={classes.chip} variant="outlined" />
                  <Chip label="NodeJS / ExpressJS" className={classes.chip} variant="outlined" />
                  <Chip label="MySQL" className={classes.chip} variant="outlined" />
                </p>
              </VerticalTimelineElement>
              
            </VerticalTimeline>
        </Element>

        {
          this.state.isLightboxOpen && (
            <Lightbox
              mainSrc={ this.slides[companySlidesBeingViewed][photoIndex] }
              onCloseRequest={() => this.setState({ isLightboxOpen: false })}

              nextSrc={this.slides[companySlidesBeingViewed][(photoIndex + 1) % this.slides[companySlidesBeingViewed].length]}
              prevSrc={this.slides[companySlidesBeingViewed][(photoIndex + this.slides[companySlidesBeingViewed].length - 1) % this.slides[companySlidesBeingViewed].length]}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + this.slides[companySlidesBeingViewed].length - 1) % this.slides[companySlidesBeingViewed].length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % this.slides[companySlidesBeingViewed].length,
                })
              }
            />
          )
        }
      </div>
    );
  }

}

const mapStateToProps = (state, props) => ({});
const mapDispatchToProps = (dispatch, props) => ({});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Portfolio));