import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomePage from '../components/Home/HomePage';
// import ProductPage from '../components/Product/ProductPage';
// import HelpPage from '../components/Help/HelpPage';
import NotFoundPage from '../components/NotFound/NotFoundPage';
// import SummaryPage from '../components/Summary/SummaryPage';
// import FavoriteProducts from '../components/User/FavoriteProducts';
// import SavedCarts from '../components/User/SavedCarts';
// import ResetPasswordRequest from '../components/ResetPasswordRequest/ResetPasswordRequest';
// import ResetPasswordRequestForm from '../components/ResetPasswordRequestForm/ResetPasswordRequestForm';

const AppRouter = () => (
  <BrowserRouter>
    <div>
      <Switch>
        <Route path="/" component={HomePage} exact={true} />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  </BrowserRouter>
);

export default AppRouter;
