const FONT_COLOR = '#404d5b';

export default {
  FONT_COLOR: FONT_COLOR,
  externalLink: {
    cursor: 'pointer',
    borderBottom: '1px solid rgba(52,73,94,0.4)',
    fontWeight: 500,
    '-webkit-transition': 'background-color 0.3s, border-color 0.3s',
    transition: 'background-color 0.3s, border-color 0.3s',
    position: 'relative',
    display: 'inline-block',
    outline: 'none',
    color: FONT_COLOR,
    verticalAlign: 'bottom',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      borderColor: 'rgba(52,73,94,0)',
      backgroundColor: 'rgba(52,73,94,0.1)'
    },
    '&:visited': {
      color: FONT_COLOR
    }
  }

};
