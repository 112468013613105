import React, { Component, Fragment, ReactDOM } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { fade } from '@material-ui/core/styles/colorManipulator';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Link from '@material-ui/icons/Link';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Menu from '@material-ui/icons/Menu';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Profile from '../Profile/Profile';
import About from '../About/About';
import Portfolio from '../Portfolio/Portfolio';
import Certifications from '../Certifications/Certifications';
import ContactMe from '../ContactMe/ContactMe';

import { Link as ReactScrollLink, Element, animateScroll as scroll, scroller } from 'react-scroll';
import Headroom from 'react-headroom';
import Fade from 'react-reveal/Fade';
import Swing from 'react-reveal/Swing';

let drawerWidth = 300;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  root: {
    // display: '100%'
    // backGroundColor: '##fafafa'
  },
  grow: {
    flexGrow: 1
  },
  appBar: {
    boxShadow: 'none',
    paddingRight: theme.spacing.unit
  },
  content: {
    height: '100vh',
    padding: theme.spacing.unit,
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing.unit * 10
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing.unit * 1.5
    }
  },
  scrollUp: {
    top: 'auto',
    right: '0px !important',
    left: '20px',
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
    // ,
    // height: '80px'
  },
  profile: {
    // marginTop: theme.spacing.unit * 5,
    height: '90%'
  },
  section: {
    // marginTop: theme.spacing.unit * 10
    // minHeight: '50vh',
    marginTop: theme.spacing.unit * 10
  },
  sideDrawer: {
    width: drawerWidth + 'px'
  }
});

class HomePage extends Component {

  state = {
    isHeadRoomShowing: true,
    isCartDrawerOpen: false,
    resumeRequestModal: false
  }

  constructor (props) {
    super(props);
  }

  componentDidMount() {
  //   setTimeout(() => {
      // window.scrollTo(0, 0)
  //   }, 200);
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.location !== prevProps.location) {
  //     window.scrollTo(0, 0);
  //   }
  // }


  // componentDidUpdate() {
  //   ReactDOM.findDOMNode(this).scrollTop = 0
  // }

  onPin = () => {
    // this.setState({
    //   isHeadRoomShowing: true
    // })
  }

  onUnpin = () => {
    // this.setState({
    //   isHeadRoomShowing: false
    // })
  }

  onScrollUp = () => {
    scroll.scrollToTop();
  }

  goToSection = (elementID, hideSidePanel) => {

    if (hideSidePanel) {
      this.closeCartDrawer()
    }

    scroller.scrollTo(elementID, {
      duration: 1000,
      delay: 100,
      smooth: true,
      // offset: 50, // Scrolls to element + 50 pixels down the page
    });
  }

  onDownloadResume = () => {
    window.open('./resources/resume/francis-caoile-resume-phone-masked.pdf');
    // this.handleClickOpen();
  }

  renderSidePanel = () => {
    const { classes } = this.props;

    return (
      <SwipeableDrawer
        anchor={ 'right' }
        open={this.state.isCartDrawerOpen}
        onOpen={() => {}}
        onClose={this.closeCartDrawer}
      >
        <List 
          component="nav" 
          className={classes.sideDrawer}
        >
          <ListItem button>
            <ListItemText primary="About Me" onClick={ () => {this.goToSection('aboutme', true)} } />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Projects" onClick={ () => {this.goToSection('portfolio', true)} } />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Certifications" onClick={ () => {this.goToSection('certifications', true)} } />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Contact Me" onClick={ () => {this.goToSection('contactme', true)} } />
          </ListItem>
        </List>
      </SwipeableDrawer>
    )
  }

  openCartDrawer = () => {
    this.setState({ isCartDrawerOpen: true });
  }

  closeCartDrawer = () => {
    this.setState({ isCartDrawerOpen: false });
  }

  handleClickOpen = () => {
    this.setState({ resumeRequestModal: true });
  };

  handleClose = () => {
    this.setState({ resumeRequestModal: false });
  };

  render () {
    const { classes } = this.props;
  
    return (
      <div className={classes.root}>
        <Headroom
          onPin={this.onPin}
          onUnpin={this.onUnpin}
          >
          <AppBar color="inherit" position="static" className={ classes.appBar }>
            <Fade top delay={500}>
              <Toolbar disableGutters={true} variant="dense">
            
                <div className={classes.grow} />
              
                <Hidden smDown>
                  <Button size="small" onClick={ () => {this.goToSection('aboutme')} }>About</Button>
                  <Button size="small" onClick={ () => {this.goToSection('portfolio')} }>Projects</Button>
                  <Button size="small" onClick={ () => {this.goToSection('certifications')} }>Certifications</Button>
                  <Button size="small" onClick={ () => {this.goToSection('contactme')} }>Contact Me</Button>
                  &nbsp;
                </Hidden>

                {/* <Button variant="outlined" onClick={ this.onDownloadResume }>Resume</Button> */}

                <Hidden mdUp>
                  <IconButton onClick={this.openCartDrawer}>
                    <Menu />
                  </IconButton>
                </Hidden>
              </Toolbar>
            </Fade>
          </AppBar>
        </Headroom>
        <Grid container alignItems="center" alignContent="center" justify="center" className={classes.content}>
          <Grid item xs={12} sm={12} md={11} lg={7} xl={6} className={classes.content}>
            
            <div className={classes.profile}>
              <Profile />
            </div>
            
            <div className={classes.section}>
              <About />
            </div>
            <div className={classes.section}>
              <Portfolio />
            </div>
            <div className={classes.section}>
              <Certifications />
            </div>
            <div className={classes.section}>
              <ContactMe />
            </div>
          </Grid>
        </Grid>

        {
          this.renderSidePanel()
        }

        {
          !this.state.isHeadRoomShowing && (
            <Fab 
              color="inherit" 
              className={ classes.scrollUp }
              aria-label="scrollUp"
              size="large"
              variant="round"
              onClick={this.onScrollUp}>
              <ArrowUpward />
            </Fab>
          )
        }

        <Dialog
          open={this.state.resumeRequestModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Resume
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Resume Available Upon Request
              <br />
              <br />
              📩 hello@franciscaoile.com
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({});
const mapDispatchToProps = (dispatch, props) => ({});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(HomePage));