import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fade from 'react-reveal/Fade';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import ArrowRight from '@material-ui/icons/ArrowRight';
import Divider from '@material-ui/core/Divider';

import { Element } from 'react-scroll';
import UIHelper from '../../utility/ui-helper';

const styles = theme => ({ 
  root: {

  },
  aboutMe: {
  },
  aboutMeContent: {
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    fontSize: '1em',
    lineHeight: 1.518
  },
  techStackContainer: {
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    color: '#404d5b'
  },
  'externalLink': {
    ...UIHelper.externalLink
  }
});

class About extends Component {

  state = {
  }

  constructor (props) {
    super(props);
  }

  render = () => {
    const { classes } = this.props;
    return (
      <div className={classes.aboutMe}>
        <Element name='aboutme'>
          <Fade clear delay={200}>
            <Divider variant="middle" />
            <br />
            <br />
            <Typography variant="h4" gutterBottom={true}>About Me</Typography>

            <div className={classes.aboutMeContent}>
              <Typography variant="p" gutterBottom={true}>
                I'm a full-stack software engineer who enjoys building things that live on the internet. I graduated with honours from <a className={classes.externalLink} href="https://bcit.ca" target="_blank"> British Columbia Institute of Technology</a> in Computer Systems Technology - Database Option.
              </Typography>
              <br />
              <Typography variant="p" gutterBottom={true}>
                I enjoy constantly learning and improving my skills with the many technologies that power the web. I have a particular interest in all things related to Progressive Web Apps and Cloud Computing.
              </Typography>
            </div>
            <br />
            <br />

            <Typography variant="subtitle1">
              🛠 Here's a few things I'm experienced with:
            </Typography>
            <Grid container className={classes.techStackContainer}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <ul>
                  <li>
                    <Typography variant="overline" >
                      ReactJS, Redux
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="overline" >
                      Angular 2+
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="overline" >
                      Ionic 2+
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="overline" >
                      NodeJS / ExpressJS
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="overline" >
                      Cordova, iOS, Android
                    </Typography>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <ul>
                  <li>
                    <Typography variant="overline" >
                      Typescript, ES6
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="overline" >
                      Java
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="overline" >
                      PHP
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="overline" >
                      MySQL, MongoDB, ElasticSearch
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="overline" >
                      Amazon Web Services
                    </Typography>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Fade>
        </Element>
      </div>
    );
  }

}

const mapStateToProps = (state, props) => ({});
const mapDispatchToProps = (dispatch, props) => ({});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(About));