import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Fade from 'react-reveal/Fade';
import Avatar from '@material-ui/core/Avatar';

import UIHelper from '../../utility/ui-helper';
import { Element } from 'react-scroll';

const styles = theme => ({ 
  root: {

  },
  contactMe: {
    textAlign: 'center',

    marginBottom: theme.spacing.unit * 20,
  },
  mailTo: {
    cursor: 'pointer',
    color: '#404d5b',
    '&:hover': {
      color: UIHelper.FONT_COLOR
    },
    '&:visited': {
      color: UIHelper.FONT_COLOR
    }
  }
});

class ContactMe extends Component {

  state = {
  }

  constructor (props) {
    super(props);
  }

  getInTouch = () => {
    window.location.href = "mailto:hello@franciscaoile.com?subject=Hi";
  }

  render = () => {
    const { classes } = this.props;
    return (
      <div className={classes.contactMe}>
        <Element name='contactme'>
          <Fade clear delay={200}>
            <Divider variant="middle" />
            <br />
            <br />
            <Typography variant="h4" gutterBottom={true} align="center">Get In Touch</Typography>
            <Typography 
              variant="p"
              gutterBottom={true}
              align="center">
              My inbox is always open. Whether for a potential project or just to say hi, I'll try my best to answer your email!
            </Typography>
            <br />
            <Typography variant="subtitle1" gutterBottom={true}><strong>📩 <a href="mailto:hello@franciscaoile.com" className={classes.mailTo}>hello@franciscaoile.com</a></strong></Typography>
            <br />
            <Button variant="outlined" size="large" onClick={this.getInTouch}>Say Hello 👋🏼</Button>
          </Fade>
        </Element>
      </div>
    );
  }

}

const mapStateToProps = (state, props) => ({});
const mapDispatchToProps = (dispatch, props) => ({});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ContactMe));